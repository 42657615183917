$(document).ready(function () {
    $.ajax({
        url: window.csrfTokenUrl,
        method: 'GET',
        type: 'GET',
        success: function (data) {
            $('.custom-csrf-token').each(function () {
                let $this = $(this);
                let parentForm = $(this).closest('form');
                $this.replaceWith(`<input type="hidden" name="csrfmiddlewaretoken" value="${data.csrf}"/>`);
                $(parentForm).removeAttr('disabled');
            })
        }
    })
});